import React from 'react';
import './App.css';
import Home from './Home';
import { Route, Routes } from 'react-router-dom';
import Terms from './Terms';
import Privacy from './Privacy';

function App() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="terms" element={<Terms />} />
            <Route path="privacy" element={<Privacy />} />
        </Routes>
    );
}

export default App;
