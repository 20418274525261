import React, { CSSProperties, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const animStyle = (secs: Number): CSSProperties => {
    return {
        animationDelay: secs + 's',
    };
}

function Home() {
    const [isLoading, setIsLoading] = useState(true);
    const [isShowAbout, setIsShowAbout] = useState(false);

    useEffect(() => {
        let timer2: NodeJS.Timeout;

        let timer1 = setTimeout(() => {
            document.getElementById("loading")?.classList.add('animated', 'fadeOut');

            timer2 = setTimeout(() => {
                document.getElementById("loading")?.classList.remove('animated', 'fadeOut')
                document.getElementById("about")?.classList.remove('animated', 'fadeIn')
                document.getElementById("contact")?.classList.remove('animated', 'fadeIn')
                document.getElementById("work")?.classList.remove('animated', 'fadeIn')
                setIsLoading(false);
            }, 1000);
        }, 1500);


        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
        };
    }, []);

    let showAbout = (() => {
        setIsShowAbout(true);

        let timer1 = setTimeout(() => {
            document.getElementById("about_container")?.classList.remove('animated', 'slideInLeft')
        }, 800);

        return () => {
            clearTimeout(timer1);
        };
    });

    let closeAbout = (() => {
        document.getElementById("about_container")?.classList.add('animated', 'slideOutLeft');

        let timer1 = setTimeout(() => {
            document.getElementById("about_container")?.classList.remove('animated', 'slideOutLeft')
            setIsShowAbout(false);
        }, 800);

        return () => {
            clearTimeout(timer1);
        };
    });

    return (
        <div>
            {isLoading ? <div>
                <div id="loading">
                    <div id="spinner"></div>
                </div>
                <div id="particles-js"></div>
                <div id="box">
                    <div className="box1 onlywide animated bounceOutLeft" style={animStyle(1.7)}></div>
                    <div className="box2 onlywide animated bounceOutLeft" style={animStyle(1.8)}></div>
                    <div className="box2 onlywide animated bounceOutLeft" style={animStyle(1.9)}></div>
                    <div className="box2 animated bounceOutRight" style={animStyle(1.9)}></div>
                    <div className="box2 onlywide animated bounceOutRight" style={animStyle(1.8)}></div>
                    <div className="box2 onlywide animated bounceOutRight" style={animStyle(1.7)}></div>
                </div>
            </div> : null}
            <a id="about" href='#' onClick={showAbout} className="animated fadeIn" style={animStyle(2.2)}>about</a>
            {/* <a id="work" className="animated fadeIn" style={animStyle(2.2)}>work</a> */}
            <a id="contact" href='mailto:support@betroix.com' className="animated fadeIn" style={animStyle(2.2)}>contact</a>
            <div id="middle" className="animated slideInDown" style={animStyle(2.0)}>
                <h1>Betroix</h1>
                <h2>Software Developer / Human</h2>
                <div id="menu">
                    <a onClick={showAbout} href='#'>about</a>
                    {/* <a>work</a> */}
                    <a href='mailto:support@betroix.com'>contact</a>
                </div>
                <table>
                    <tr>
                        <td className="animated zoomIn" style={animStyle(2.2)}>
                            <Link to="/terms" className="btn btn-primary">terms</Link>
                        </td>
                        <td className="animated zoomIn" style={animStyle(2.4)}>
                            <Link to="/privacy" className="btn btn-primary">privacy</Link>
                        </td>
                        {/* <td className="animated zoomIn" style={animStyle(2.6)}><a className="social"><i className="fab fa-instagram"></i></a></td> */}
                        {/* <td className="animated zoomIn" style={animStyle(2.8)}><a className="social"><i className="fab fa-dribbble"></i></a></td> */}
                    </tr>
                </table>
            </div>
            {isShowAbout ? <div>
                <div id="about_container" className="container animated slideInLeft">
                    <div onClick={closeAbout}><i className="fas fa-angle-left"></i></div>
                    <h1>about.</h1>
                    <section>
                        <h2>about us</h2>
                        <p>
                            Hi! We're an app developer website and we make apps for people. We've been in business for a while and we're always looking for new and innovative ways to make apps. We're always on the lookout for new and interesting ideas, so if you have an idea for an app, we'd love to hear from you!
                        </p>

                        <p>
                            We've developed a wide range of apps, from games to productivity tools, and we're always looking to add more to our portfolio. We're passionate about making great apps that people will love to use.
                        </p>

                        <p>
                            If you're interested in working with us, or if you just want to find out more about what we do, we'd love to hear from you.
                        </p>
                    </section>
                </div>
            </div> : null}
        </div>
    );
}

export default Home;
